import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from 'nanoid';
// eslint-disable-next-line import/no-cycle
import renderComponents from '../DynamicForm/lib/renderComponents';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';

function SimpleTable(props) {
  const {
    hasHeaderRow = false,
    control,
    formId,
    name,
    formMode = DYNAMIC_FORM_MODE.VIEW,
    helperText,
    label,
    rows = [],
    jsonSchema = {},
    masterData,
    hasConstantValue,
    additionalComponents = {},
    containerComponent,
    effectFunctions = null,
    formatMessage,
    setValue,
    dataType,
    columnsIndexToHide = [],
    rowsIndexToHide = [],
    getValues,
    itemsArrayIndex,
    additionalComponentKey,
    ...simpleTableProps
  } = props;

  const HeaderRow = hasHeaderRow ? rows[0].data : null;
  const ContentRows = hasHeaderRow ? rows.slice(1) : rows;

  const headers = HeaderRow.map((rowContentItem, colIndex) =>
    columnsIndexToHide.includes(colIndex) ? null : (
      <TableCell key={nanoid()}>
        {renderComponents({
          uiSchema: rowContentItem,
          additionalComponents,
          control,
          jsonSchema,
          formMode,
          masterData,
          formId,
          formatMessage,
        })}
      </TableCell>
    ),
  );

  return (
    <TableContainer {...simpleTableProps}>
      <Table style={{ width: '100%' }}>
        {HeaderRow && (
          <TableHead>
            <TableRow key={nanoid()}>{headers}</TableRow>
          </TableHead>
        )}
        <TableBody>
          {ContentRows.map((rowItems, rowIndex) =>
            rowsIndexToHide.includes(rowIndex) ? null : (
              <TableRow
                key={nanoid()}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}>
                {rowItems.data.map((rowContentItem, colIndex) =>
                  columnsIndexToHide.includes(colIndex) ? null : (
                    <TableCell key={nanoid()}>
                      {renderComponents({
                        uiSchema: rowContentItem,
                        additionalComponents,
                        control,
                        jsonSchema,
                        formMode,
                        masterData,
                        formId,
                        effectFunctions,
                        formatMessage,
                        setValue,
                      })}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SimpleTable;
