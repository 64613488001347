import { useTheme } from '@mui/material/styles';
import { Button, Menu, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useState } from 'react';
import MenuItem from '../MenuItem';

function MenuButton({
  buttonTitle,
  StartIcon = null,
  minMenuItemWidth = '200px',
  menuItems = [],
  MenuProps = {},
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StartIconProps = {
    ...(StartIcon !== null && { startIcon: <StartIcon /> }),
  };

  return (
    <div>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        {...StartIconProps}>
        {buttonTitle}
      </Button>
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: minMenuItemWidth,
            borderRadius: '15px',
            border: `1px solid ${theme.palette.secondary.light}`,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...MenuProps}>
        {menuItems.map(item => (
          <MenuItem
            key={item.label}
            Icon={item.icon}
            IconProps={{
              sx: { width: '20px', color: theme.palette.tertiary.main },
            }}
            onClick={() => {
              item.onClick();
              handleClose();
            }}>
            <Typography variant="subtitle2">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default MenuButton;
