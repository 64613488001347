import { lazy } from 'react';
import { Navigate, createBrowserRouter, useRouteError } from 'react-router-dom';
import { DYNAMIC_FORM_MODE } from '@servicexcelerator/claims-design-system';
import ProtectedRoute from '@components/Routing/ProtectedRoute';
import LazyLoadRoute from '@components/Routing/LazyLoadRoute';
import ErrorPage, { ERROR_PAGE_TYPE } from '@pages/ErrorPage';

const Login = LazyLoadRoute(lazy(() => import('@pages/Login')));
const Dashboard = LazyLoadRoute(lazy(() => import('@pages/Dashboard')));
const ClaimFormPage = LazyLoadRoute(lazy(() => import('@pages/Claim/index')));
const ClaimsSearch = LazyLoadRoute(lazy(() => import('@pages/Claims/search')));

// Dont want to use the react-router error handling
// Instead bubble to error so global error handler catches

const BubbleRouteError = () => {
  const error = useRouteError();
  throw error;
};

const routes = [
  {
    path: '/',
    element: <Login />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/unauthorized',
    element: <ErrorPage type={ERROR_PAGE_TYPE.FORBIDDEN} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/servicedown',
    element: <ErrorPage type={ERROR_PAGE_TYPE.SERVICE_DOWN} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/servererror',
    element: <ErrorPage type={ERROR_PAGE_TYPE.SERVER_ERROR} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute Component={Dashboard} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: 'claim',
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard" />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'create/:claimRefId',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.CREATE }}
            Component={ClaimFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'view/:claimId',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.VIEW }}
            Component={ClaimFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'edit/:claimId/:claimRefId?',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.EDIT }}
            Component={ClaimFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
  {
    path: 'claims',
    children: [
      {
        path: '',
        element: <Navigate to="/claims/search" />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'search',
        element: <ProtectedRoute Component={ClaimsSearch} />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="/claims/search" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage type={ERROR_PAGE_TYPE.NOT_FOUND} />,
    errorElement: <BubbleRouteError />,
  },
];

const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL,
});

export default router;
