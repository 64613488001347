import { Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAuth } from 'react-oidc-context';
import { useIntl } from 'react-intl';
import {
  ErrorDisplay,
  Logo,
  Page,
} from '@servicexcelerator/claims-design-system';
import { ERROR_PAGE_TYPE, getErrorMessage } from '@pages/ErrorPage';

function GlobalFallbackComponent({ error }) {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  return (
    <div>
      <Page>
        <Container>
          <Grid2 container>
            <Grid2 xs={12} sx={{ mt: 3 }}>
              <Logo href="/" />
            </Grid2>
            <Grid2 xs={12}>
              <ErrorDisplay
                {...getErrorMessage({
                  type: ERROR_PAGE_TYPE.CODE_ERROR,
                  loginAction: () => auth.signinRedirect(),
                  errors: { codeErrors: { message: error?.message } },
                  formatMessage,
                })}
              />
            </Grid2>
          </Grid2>
        </Container>
      </Page>
    </div>
  );
}

export default GlobalFallbackComponent;
