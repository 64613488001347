import { Button, Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  ErrorDisplay,
  Logo,
  Page,
} from '@servicexcelerator/claims-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { useLocation, useRouteError, useSearchParams } from 'react-router-dom';

export const ERROR_PAGE_TYPE = {
  NOT_FOUND: 'NOT_FOUND',
  AUTH_ERROR: 'AUTH_ERROR',
  FORBIDDEN: 'FORBIDDEN',
  CODE_ERROR: 'CODE_ERROR',
  SERVICE_DOWN: 'SERVICE_DOWN',
  SERVER_ERROR: 'SERVER_ERROR',
};

export function getErrorMessage({
  type = ERROR_PAGE_TYPE.CODE_ERROR,
  loginAction = () => {},
  errors = null,
  formatMessage = () => undefined,
}) {
  const loginButton = (
    <Button variant="contained" onClick={loginAction}>
      <FormattedMessage id="LOGIN_BUTTON" defaultMessage="Login" />
    </Button>
  );

  const backAndDashboardButtons = (
    <div>
      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        onClick={() => window.history.back()}>
        <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          window.location.href = '/dashboard';
        }}>
        <FormattedMessage id="DASHBOARD_BUTTON" defaultMessage="Dashboard" />
      </Button>
    </div>
  );

  const errorMessages = {
    NOT_FOUND: {
      text: formatMessage({
        id: 'RESOURCE_NOT_FOUND',
        defaultMessage: 'Resource not found',
      }),
      subText: formatMessage({
        id: 'RESOURCE_NOT_FOUND_SUBTEXT',
        defaultMessage:
          'We could not find the resource you were looking for. You can try going back or to the dashboard.',
      }),
      errorMessage: errors?.routeError?.message,
      buttonComponent: backAndDashboardButtons,
    },
    SERVER_ERROR: {
      text: formatMessage({
        id: 'UNPROCESSABLE_REQUEST',
        defaultMessage: 'Unprocessable request',
      }),
      subText: formatMessage({
        id: 'UNPROCESSABLE_REQUEST_SUBTEXT',
        defaultMessage:
          'The server is unable to process your request at this time. You can try going back or to the dashboard.',
      }),
      errorMessage: errors?.serverError?.message
        ? decodeURIComponent(errors?.serverError?.message)
        : null,
      buttonComponent: backAndDashboardButtons,
    },
    SERVICE_DOWN: {
      text: formatMessage({
        id: 'SERVICE_UNAVAILABLE',
        defaultMessage: 'Service Unavailable',
      }),
      subText: formatMessage({
        id: 'SERVICE_UNAVAILABLE_SUBTEXT',
        defaultMessage:
          'The resource you are trying to access is unavailable at this time. Please retry the action after some time.',
      }),
      errorMessage: errors?.networkError?.message
        ? decodeURIComponent(errors?.networkError?.message)
        : null,
      buttonComponent: backAndDashboardButtons,
    },
    AUTH_ERROR: {
      text: formatMessage({
        id: 'AUTHENTICATION_ERROR',
        defaultMessage: 'Authentication Error',
      }),
      subText: formatMessage({
        id: 'AUTHENTICATION_ERROR_SUBTEXT',
        defaultMessage:
          'We are unable to authenticate your action currently. Please login and retry the action.',
      }),
      errorMessage: errors?.authError?.message,
      buttonComponent: loginButton,
    },
    FORBIDDEN: {
      text: formatMessage({
        id: 'UNAUTHORIZED_ACCESS',
        defaultMessage: 'UnAuthorized Access',
      }),
      subText: formatMessage({
        id: 'UNAUTHORIZED_ACCESS_SUBTEXT',
        defaultMessage:
          'You do not have permissions to access this resource. Please login and retry the action.',
      }),
      errorMessage: formatMessage({
        id: 'ACCESS_DENIED',
        defaultMessage: 'Access Denied',
      }),
      buttonComponent: loginButton,
    },
    CODE_ERROR: {
      text: formatMessage({
        id: 'OOPS_SOMETHING_WENT_WRONG',
        defaultMessage: 'Oops! Something went wrong.',
      }),
      subText: formatMessage({
        id: 'OOPS_SOMETHING_WENT_WRONG_SUBTEXT',
        defaultMessage:
          'We are unable to process your request at this time. You can try going back or to the dashboard.',
      }),
      errorMessage: errors?.codeErrors?.message,
      buttonComponent: backAndDashboardButtons,
    },
  };
  const errorDescriptionLabel = formatMessage({
    id: 'ERROR_DESCRIPTION',
    defaultMessage: 'Error Description:',
  });

  errorMessages[
    type
  ].errorMessage = `${errorDescriptionLabel} ${errorMessages[type].errorMessage}`;

  return errorMessages[type];
}

function ErrorPage({ type = ERROR_PAGE_TYPE.CODE_ERROR }) {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const routeError = useRouteError();
  const { state } = useLocation();

  let redirectArgs = {};

  if (state?.redirect_uri) {
    redirectArgs = { redirect_uri: state?.redirect_uri };
  }

  const authError = auth?.error;
  const loginAction = () => auth.signinRedirect(redirectArgs);
  const [searchParams] = useSearchParams();
  const networkErrorTxt = searchParams.get('networkError');
  const networkError = networkErrorTxt ? JSON.parse(networkErrorTxt) : null;

  const serverErrorTxt = searchParams.get('serverError');
  const serverError = serverErrorTxt ? JSON.parse(serverErrorTxt) : null;

  return (
    <Page>
      <Container>
        <Grid2 container>
          <Grid2 xs={12} sx={{ mt: 3 }}>
            <Logo href="/" />
          </Grid2>
          <Grid2 xs={12}>
            <ErrorDisplay
              {...getErrorMessage({
                type,
                loginAction,
                errors: {
                  routeError,
                  authError,
                  networkError,
                  serverError,
                },
                formatMessage,
              })}
            />
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  );
}

export default ErrorPage;
