import { RootStore as MobxRootStore } from '@servicexcelerator/claims-design-system';
import CreateClaimModalStore from './ui/CreateClaimModal';
import ClaimSearchFormStore from './ui/ClaimSearchForm';
import ClaimsStore from './domain/Claims';
import MasterDataStore from './domain/MasterData';
import FormStateStore from './domain/FormState';
import UserAccessStore from './domain/UserAccess';
import LocaleStore from './domain/Locale';

function RootStore() {
  const rootStore = new MobxRootStore();

  rootStore.addDomainStore('claims', new ClaimsStore(rootStore));
  rootStore.addDomainStore('masterData', new MasterDataStore(rootStore));
  rootStore.addDomainStore('formState', new FormStateStore(rootStore));
  rootStore.addDomainStore('userAccess', new UserAccessStore(rootStore));
  rootStore.addDomainStore('locale', new LocaleStore(rootStore));

  rootStore.addUiStore(
    'createClaimModal',
    new CreateClaimModalStore(rootStore),
  );
  rootStore.addUiStore('claimSearchForm', new ClaimSearchFormStore(rootStore));

  return rootStore;
}

export default RootStore;
