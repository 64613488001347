import { ErrorBoundary } from 'react-error-boundary';
import { LoggerUtil } from '@servicexcelerator/claims-design-system';
import GlobalFallbackComponent from '@components/ErrorHandling/GlobalErrorHandler/GlobalFallbackComponent';

function GlobalErrorHandler({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={GlobalFallbackComponent}
      onError={(error, errorInfo) =>
        LoggerUtil.log('GlobalErrorHandler', { error, errorInfo })
      }
      onReset={() => {
        window.location.reload();
      }}>
      {children}
    </ErrorBoundary>
  );
}

export default GlobalErrorHandler;
